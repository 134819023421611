import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { FaGithub, FaLinkedin, FaYoutube } from 'react-icons/fa';

import useDarkMode from '../../hooks/useDarkMode.ts';
import { lightTheme, darkTheme } from '../../assets/theme/theme';
import GlobalStyle from '../../assets/globalStyle';
import latestPortPic from '../../assets/images/latestPortPic.png';

const App = styled.div`
  margin: 0 auto;
  width: 50%;
  background-color: ${({ theme }) => theme.backgroundColor};
  cursor: default;
  transition: 300ms ease-in-out;

  @font-face {
    font-family: CooperHewitt;
    src: url('../assets/webfonts/CooperHewitt-Bold.otf');
  }

  @media screen and (max-width: 800px) {
    font-size: 80%;
    width: 80%;
    margin: 0 auto;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  padding-bottom: 140px;
  font-size: 16px;
  color: ${({ theme }) => theme.text};
  text-align: center;

  @media screen and (max-width: 800px) {
    padding-top: 60px;
    font-size: 80%;
    width: 100%;
  }
`;

const Name = styled.h1`
  font-family: 'Spartan', sans-serif;
  padding-top: 40px;
  padding-bottom: 12px;
  font-size: 2em;
`;

const Description = styled.p`
  font-size: 16px;
  margin-top: 5px;
  font-weight: 500;
  letter-spacing: 1px;

  @media screen and (max-width: 800px) {
    font-size: 2em;
  }
`;

const Profile = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

const Link = styled.a`
  color: #3d6098;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }

  @media screen and (max-width: 800px) {
    font-size: 2em;
  }
`;

const UnderHeader = styled.h2`
  text-decoration: underline;
  padding-top: 40px;
  font-weight: 900;

  @media screen and (max-width: 800px) {
    font-size: 2em;
  }
`;

const Image = styled.img`
  width: 30%;
  margin: 0 auto;
  min-height: 200px;

  @media screen and (max-width: 950px) {
    width: 40%;
  }

  @media screen and (max-width: 800px) {
    width: 60%;
  }
`;

const GitHubIcon = styled(FaGithub)`
  width: 30px;
  height: 30px;
  margin-top: 20px;
  cursor: pointer;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 0.7;
  }
`;

const LinkedinIcon = styled(FaLinkedin)`
  width: 30px;
  height: 30px;
  margin-top: 20px;
  cursor: pointer;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 0.7;
  }
`;

const YoutubeIcon = styled(FaYoutube)`
  width: 30px;
  height: 30px;
  margin-top: 20px;
  cursor: pointer;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 0.7;
  }
`;

const Icons = styled.div`
  display: flex;
  justify-content: center;

  & a {
    margin: 0 8px;
  }
`;

const calculateAge = dob => {
  const birthDate = new Date(dob);
  const today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age -= 1;
  }
  return age;
};

const About = () => {
  const [theme, componentMounted] = useDarkMode();
  const themeMode = theme === 'light' ? lightTheme : darkTheme;

  if (!componentMounted) {
    return <div />;
  }

  const age = calculateAge('1986-02-06');

  return (
    <ThemeProvider theme={themeMode}>
      <App>
        <GlobalStyle />
        <Wrapper>
          <Image src={latestPortPic} alt="Greg Profile Pic" />
          <Name>Hi, My name is Greg</Name>
          <Description>
            I am a {age} year old self taught web developer from OKC
            specializing in front-end development, I am experienced with all
            stages of development cycle for dynamic web projects. Well-versed in
            HTML, CSS, and Javascript. Im a former Pro MMA fighter and boxer,
            and blue belt in BJJ (American Top Team) and now most of my time
            goes to my 5 year old daughter.
          </Description>
          <Link href="https://docs.google.com/document/d/1jWR6xIpKlUwusSOehT6gzaaogU-L99vXRX26d67s92c/edit?usp=sharing">
            Resume
          </Link>
          <Profile>
            <UnderHeader>Certifications</UnderHeader>
            <Link href="https://aws.amazon.com/certification/certified-solutions-architect-associate/">
              AWS Certified Solutions Architect - Associate (Active Jan, 31,
              2023)
            </Link>
            <Link href="https://aws.amazon.com/certification/certified-developer-associate/?trk=1d3789b7-cdfb-4b92-a125-75424f21eaaf&sc_channel=ps&ef_id=Cj0KCQiAjMKqBhCgARIsAPDgWlyzVb951DD58adgmjiBlsyvPRKZVOZisrulTIez7dWpb7IgFUeBdioaAqDkEALw_wcB:G:s&s_kwcid=AL!4422!3!508672713499!e!!g!!aws%20certified%20developer!11120345480!106933362222">
              AWS Certified Developer - Associate (Active Oct 20, 2023)
            </Link>
            <Link href="https://www.istqb.org/certification-path-root/agile-tester.html">
              ISTBQ CTFLA-AT (Certified Tester, Foundation Level Agile Tester)
            </Link>
          </Profile>
          <Icons>
            <a
              href="https://github.com/gregdbanks"
              target="_blank"
              rel="noopener noreferrer"
            >
              <GitHubIcon />
            </a>
            <a
              href="https://www.linkedin.com/in/greg%F0%9F%91%8D-banks-864aa569/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LinkedinIcon />
            </a>
            <a
              href="https://www.youtube.com/channel/UCg0Qjyopa54URU-oWC3qs-A"
              target="_blank"
              rel="noopener noreferrer"
            >
              <YoutubeIcon />
            </a>
          </Icons>

          {/* <UnderHeader>Fun Projects</UnderHeader>
          <Link href="https://gregdbanks.github.io/gh-pages/">Yahtzee!</Link> */}
        </Wrapper>
      </App>
    </ThemeProvider>
  );
};

export default About;
