import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import styled, { ThemeProvider } from 'styled-components';
import { MDXProvider } from '@mdx-js/react';
import { CodePen, Gist } from 'mdx-embed';

import { lightTheme, darkTheme } from './assets/theme/theme';
import GlobalStyle from './assets/globalStyle';
import useDarkMode from './hooks/useDarkMode';
import NavBar from './components/NewPortNav';
import Home from './Pages/Home/Home';
import About from './Pages/About/About';
import Repos from './Pages/Repos/Repos';
import VideoWrapper from './Pages/Videos/Videos';
import AuthAPI from './Pages/Videos/AuthAPI/AuthAPI';
import useNetworkStatus from './hooks/useNetworkStatus';
import './styles.css';

const components = {
  CodePen,
  Gist,
};

const Container = styled.div`
  display: inline;
`;

const NetworkStatus = styled.div`
  text-align: center;
  padding: 10px;
  color: ${({ theme }) => theme.text};
  background: ${({ theme }) => theme.backgroundSecondary};
`;

const Main = styled.div``;

const App: React.FC = () => {
  const [theme, componentMounted] = useDarkMode();
  const isOnline = useNetworkStatus();
  const themeMode = theme === 'light' ? lightTheme : darkTheme;

  if (!componentMounted) {
    return <div />;
  }

  return (
    <MDXProvider components={components}>
      <ThemeProvider theme={themeMode}>
        <Container>
          <GlobalStyle />
          <Router>
            <NavBar />
            <NetworkStatus>
              {!isOnline &&
                'You are offline. Some features may not be available.'}
            </NetworkStatus>
            <Main>
              <Switch>
                <Route path="/" exact render={() => <Home />} />
                <Route path="/About" exact render={() => <About />} />
                <Route path="/Videos" exact render={() => <VideoWrapper />} />
                <Route path="/AuthAPI" exact render={() => <AuthAPI />} />
                <Route path="/Repos" exact render={() => <Repos />} />
              </Switch>
            </Main>
          </Router>
        </Container>
      </ThemeProvider>
    </MDXProvider>
  );
};

export default App;
